var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button-toolbar',{staticClass:"mb-3",attrs:{"key-nav":""}},[_c('b-button',{attrs:{"variant":"primary","size":"sm","to":{ name: 'ProductRegister' }}},[_c('span',{staticClass:"fas fa-plus mr-1"}),_vm._v(" Cadastrar Produto ")])],1),(_vm.error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('span',{staticClass:"fas fa-exclamation-triangle mr-1"}),_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_vm._l((_vm.groupedProducts),function(products,storeName){return _c('div',{key:storeName,staticClass:"mb-2"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"d-flex justify-content-between align-items-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toggleStore(storeName)}}},[_c('span',[_vm._v(_vm._s(storeName))]),_c('span',{class:{
            'fas fa-chevron-down': !_vm.collapsedStores[storeName],
            'fas fa-chevron-right': _vm.collapsedStores[storeName]
          }})]),_c('b-collapse',{attrs:{"id":("collapse-" + storeName)},model:{value:(_vm.collapsedStores[storeName]),callback:function ($$v) {_vm.$set(_vm.collapsedStores, storeName, $$v)},expression:"collapsedStores[storeName]"}},[_c('b-table',{staticClass:"mt-2",attrs:{"fields":_vm.fields,"items":products,"striped":"","hover":"","show-empty":"","busy":_vm.isBusy},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:(_vm.getColumnStyle(field.key))})})}},{key:"cell(image)",fn:function(ref){
          var image = ref.value;
          var item = ref.item;
return [_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getProductImage(image),"alt":item.name}})]}},{key:"cell(price)",fn:function(ref){
          var price = ref.value;
return [_vm._v(" R$ "+_vm._s(price.toFixed(2))+" ")]}},{key:"cell(store)",fn:function(ref){
          var store = ref.value;
return [_vm._v(" "+_vm._s(store.name)+" ")]}},{key:"cell(status)",fn:function(ref){
          var status = ref.value;
return [_c('Status',{attrs:{"value":status}})]}},{key:"cell(actions)",fn:function(ref){
          var item = ref.item;
return [_c('BaseListActions',{attrs:{"item":item},on:{"edit":_vm.onEdit,"remove":_vm.onRemove,"updateStatus":_vm.onUpdateStatus}})]}}],null,true)},[_c('div',{staticClass:"text-center",attrs:{"slot":"empty"},slot:"empty"},[_vm._v(" Nenhum produto cadastrado ")])])],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }