<template>
  <div>
    <b-button-toolbar key-nav class="mb-3">
      <b-button variant="primary" size="sm" :to="{ name: 'ProductRegister' }">
        <span class="fas fa-plus mr-1" /> Cadastrar Produto
      </b-button>
    </b-button-toolbar>

    <b-alert v-if="error" show variant="danger">
      <span class="fas fa-exclamation-triangle mr-1" /> {{ error }}
    </b-alert>

    <div
      v-for="(products, storeName) in groupedProducts"
      :key="storeName"
      class="mb-2"
    >
      <b-card no-body>
        <!-- Cabeçalho clicável para expandir/colapsar -->
        <b-card-header
          class="d-flex justify-content-between align-items-center"
          style="cursor: pointer"
          @click="toggleStore(storeName)"
        >
          <span>{{ storeName }}</span>
          <span
            :class="{
              'fas fa-chevron-down': !collapsedStores[storeName],
              'fas fa-chevron-right': collapsedStores[storeName]
            }"
          ></span>
        </b-card-header>

        <b-collapse
          :id="`collapse-${storeName}`"
          v-model="collapsedStores[storeName]"
        >
          <b-table
            :fields="fields"
            :items="products"
            striped
            hover
            show-empty
            :busy="isBusy"
            class="mt-2"
          >
            <div slot="empty" class="text-center">
              Nenhum produto cadastrado
            </div>
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="getColumnStyle(field.key)"
              />
            </template>
            <template #cell(image)="{ value: image, item }">
              <img
                :src="getProductImage(image)"
                :alt="item.name"
                class="img-fluid"
              />
            </template>
            <template #cell(price)="{ value: price }">
              R$ {{ price.toFixed(2) }}
            </template>
            <template #cell(store)="{ value: store }">
              {{ store.name }}
            </template>
            <template #cell(status)="{ value: status }">
              <Status :value="status" />
            </template>
            <template #cell(actions)="{ item }">
              <BaseListActions
                :item="item"
                @edit="onEdit"
                @remove="onRemove"
                @updateStatus="onUpdateStatus"
              />
            </template>
          </b-table>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import services from '@/services'
import Status from '@/components/Status'
import BaseListActions from '@/components/BaseListActions'
import withStatusAction from '@/mixins/withStatusAction'
import { parseHTTPError } from '@/helpers/errors'
import { PRODUCT_DEFAULT_IMAGE } from '@/constants'

export default {
  name: 'ProductsList',
  components: {
    Status,
    BaseListActions
  },
  mixins: [withStatusAction(services.products.updateProduct)],
  data() {
    return {
      isBusy: false,
      error: null,
      allProducts: [],
      collapsedStores: {} // Armazena o estado (expandido/colapsado) de cada loja
    }
  },
  computed: {
    fields: () => [
      {
        key: 'image',
        label: 'Imagem'
      },
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'price',
        label: 'Preço'
      },
      {
        key: 'store',
        label: 'Loja'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ],
    groupedProducts() {
      const groups = {}
      for (const product of this.allProducts) {
        const storeName = product.store ? product.store.name : 'Sem Loja'
        if (!groups[storeName]) {
          groups[storeName] = []
        }
        groups[storeName].push(product)
      }
      for (const storeName in groups) {
        groups[storeName].sort((a, b) => {
          if (a.status === 'active' && b.status !== 'active') return -1
          if (a.status !== 'active' && b.status === 'active') return 1
          return 0
        })
        // Inicia todos colapsados (false = fechado)
        if (this.collapsedStores[storeName] === undefined) {
          this.$set(this.collapsedStores, storeName, false)
        }
      }

      return groups
    }
  },
  async mounted() {
    await this.provider()
  },
  methods: {
    async provider() {
      this.isBusy = true
      try {
        const products = await services.products.fetchAllProducts()
        this.allProducts = products
        return products
      } catch (error) {
        this.error = parseHTTPError(error)
        return []
      } finally {
        this.isBusy = false
      }
    },
    toggleStore(storeName) {
      this.collapsedStores[storeName] = !this.collapsedStores[storeName]
    },
    onEdit(product) {
      const { id } = product
      this.$router.push({ name: 'ProductRegister', params: { id } })
    },
    async onRemove(product) {
      const message = `Deseja realmente remover o produto ${product.name}?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        await services.products.removeProduct(product.id)
        await this.provider()
        this.$root.$toast.success('Produto removido com sucesso')
      }
    },
    async onUpdateStatus(product) {
      await this.toggleStatus(product)
      await this.provider()
    },
    getColumnStyle(field) {
      switch (field) {
        case 'actions':
          return { width: '1%' }
        case 'image':
          return { width: '15%' }
        default:
          return ''
      }
    },
    getProductImage(image) {
      return image || PRODUCT_DEFAULT_IMAGE
    }
  }
}
</script>
